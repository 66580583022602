import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  ElementRef,
  Output,
  Input,
  type OnInit,
} from '@angular/core';
import type { Asset } from '@vendure/core';
import { ActivatedRoute } from '@angular/router';
import type { FormControl } from '@angular/forms';
import { BehaviorSubject, lastValueFrom, map } from 'rxjs';

import type {
  FormInputComponent,
  InputComponentConfig,
} from '@vendure/admin-ui/core';
import {
  type SaveBrandLogoMutationMutation,
  type SaveBrandLogoMutationMutationVariables,
} from '../../generated-types/graphql';
import { DataService, NotificationService } from '@vendure/admin-ui/core';
import {
  REMOVE_BRAND_LOGO_MUTATION,
  SAVE_BRAND_LOGO_MUTATION,
} from '../../queries';

/**
 * @description
 * A form control for uploading a brand logo. (Branded Promotions)
 *
 * @docsCategory components
 */
@Component({
  selector: 'brand-logo-input',
  templateUrl: './brand-logo-input.component.html',
  styleUrls: ['./brand-logo-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandLogoInputComponent implements FormInputComponent, OnInit {
  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.promotionId = params.id as string;
    });
  }

  static readonly id = 'brand-logo-input';
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Output() selectedValue = new BehaviorSubject<string | null>(null);
  @Input() readonly!: boolean;

  formControl!: FormControl;
  config!: InputComponentConfig;
  accept = ['image/jpeg', 'image/png', 'image/jpg'];

  /** We only allow uploading when there's a valid promotionId */
  disabled$ = this.activatedRoute.params.pipe(
    map((params) => params.id === 'create'),
  );

  /** Show a preview of the uploaded image */
  imagePreviewSrc = new BehaviorSubject<string | null>(null);

  /** The ID of the current promotion */
  promotionId: string | null = null;

  ngOnInit(): void {
    const preview = (this.formControl.value as Asset | null)?.preview;
    if (preview) this.imagePreviewSrc.next(preview);
  }

  async onChange(event: Event) {
    const files = (event.target as HTMLInputElement).files ?? [];
    const file = files[0];

    if (this.readonly) return;
    if (!file) return;

    const fileObjectSrc = URL.createObjectURL(file);
    this.imagePreviewSrc.next(fileObjectSrc);

    const promotion = await this.saveToPromotion(file);
    const assetId = promotion?.saveBrandLogo.customFields?.brandLogo?.id;
    if (!promotion || !assetId) {
      this.notificationService.error(
        'Unable to save the uploaded brand logo. If this keeps occurring, please contact DEV.',
      );
      return;
    }

    this.selectedValue.next(assetId);
    this.notificationService.success(
      'Successfully uploaded the brand logo for this promotion.',
    );
  }

  /**
   * Save the uploaded image to the promotion.
   */
  async saveToPromotion(file: File) {
    if (!this.promotionId) {
      this.notificationService.error(
        'Unable to get the promotion id which is required for uploading a brand logo. If this keeps occurring, please contact DEV.',
      );
      return;
    }

    const observable = this.dataService.mutate<
      SaveBrandLogoMutationMutation,
      SaveBrandLogoMutationMutationVariables
    >(SAVE_BRAND_LOGO_MUTATION, {
      promotionId: this.promotionId,
      file,
    });

    return lastValueFrom(observable);
  }

  async removeFromPromotion() {
    if (!this.promotionId) {
      this.notificationService.error(
        'Unable to get the promotion id which is required for uploading a brand logo. If this keeps occurring, please contact DEV.',
      );
      return;
    }

    const observable = this.dataService.mutate(REMOVE_BRAND_LOGO_MUTATION, {
      promotionId: this.promotionId,
    });

    return lastValueFrom(observable);
  }

  /**
   * Removes the stored key from the promotion and clears the image preview.
   * Removing the key from the promotion will automatically delete the image from S3.
   */
  async clearImage() {
    this.imagePreviewSrc.next(null);
    this.fileInput.nativeElement.value = '';

    const promotion = await this.removeFromPromotion();
    if (promotion) {
      this.notificationService.success(
        'Successfully removed the brand logo from this promotion.',
      );
      return;
    }

    this.notificationService.error(
      'Unable to remove the uploaded brand logo. If this keeps occurring, please contact DEV.',
    );
  }
}
