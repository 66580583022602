<div>
  <input
    type="file"
    class="brand-logo-input"
    (change)="onChange($event)"
    [accept]="accept.join(', ')"
    [disabled]="(disabled$ | async) || readonly"
    #fileInput
  />

  <p class="brand-logo-preview-disabled-helper-text" *ngIf="disabled$ | async">
    A brand logo can only be uploaded once the promotion has been created.
  </p>

  <div class="brand-logo-preview" *ngIf="imagePreviewSrc | async">
    <img [src]="imagePreviewSrc | async" />

    <button [disabled]="readonly" class="button danger" (click)="clearImage()">
      Remove brand logo
    </button>
  </div>
</div>
